import axios from "axios";

// export const baseURL = `http://192.168.165.251:3010/api/`;
//  export const baseURL = `http://91.108.105.176/api/`; //grand castle marquee
//  export const baseURL = `http://demoerp.ebeaconsofts.com/api/`; //demo
// export const baseURL = `https://erp.superfinebicycles.com/api/`; //biCycle
//  export const baseURL = `http://35.154.101.91/api/`; //green apple
export const baseURL=`https://erpnaqeebe.ebeaconsofts.com/api/`; //naqeebe
export const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${window.localStorage.getItem("token")}`,
};
const instance = axios.create({
  baseURL: baseURL,
  headers: headers,
  responseType: "json",
});
function createAxiosResponseInterceptor(axiosInstance) {
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }
      axiosInstance.interceptors.response.eject(interceptor);
      window.localStorage.clear();
      window.location.replace("/login");
      return Promise.reject(error);
    }
  );
}

createAxiosResponseInterceptor(instance);

export default instance;
